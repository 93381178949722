// extracted by mini-css-extract-plugin
export var terms = "style-module--terms--2Px0W";
export var terms__head = "style-module--terms__head--2OgFz";
export var title = "style-module--title--MmgRW";
export var terms__body = "style-module--terms__body--1EVtJ";
export var menu = "style-module--menu--3yF8w";
export var list = "style-module--list--32ZeI";
export var item = "style-module--item--3g5Jy";
export var dropdown = "style-module--dropdown--ccpKy";
export var dropdown__item = "style-module--dropdown__item--19h6d";
export var content = "style-module--content--FP36j";
export var content__group = "style-module--content__group--2cByV";
export var roundedBox = "style-module--roundedBox--2ng6s";