import React from "react"
import { Scrollbars } from 'react-custom-scrollbars';
import { graphql } from "gatsby"
import { Layout, Container, Seo, RoundedBox } from "../../components"
import { TriangleRight } from '../../assets/vectors'

import * as styles from './style.module.scss'

let defaultWindowSize = 163
let onClickedWindowSize = 363

if ( typeof window !== 'undefined' ) {
	if ( window.innerWidth < 1024 ) {
		defaultWindowSize = 120
		onClickedWindowSize = 290
	}
}

const TermsTemplate = ({ data, pageContext }) => {
	const [ termsMarginTop, setTermsMarginTop ] = React.useState(defaultWindowSize)

  const { termsFields: terms, seoMetaFields } = data.terms
  const { termsFields: privacy } = data.privacy
  const { termsFields: rules } = data.rules

  const termsMeta = seoMetaFields.wpFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const termsContent = terms.sectionTerms.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const privacyContent = privacy.sectionTerms.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const rulesContent = rules.sectionTerms.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const kebabify = text =>
    text && text.toLowerCase().replace(/ |&/gi, "-").replace(/(---)/gi, "-")

  return (
    <Layout>
      <Seo title={termsMeta.seoTitle} description={termsMeta.seoDescription} />

			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-800px",
					right: "100px"
				}}
			/>

			<section className={styles.terms} style={{marginTop: termsMarginTop + "px"}}>
				<Container>
					<div className={styles.terms__head}>
						<h1 className={styles.title}>{termsContent.title}</h1>
					</div>
					<div className={`${styles.terms__body} flex`}>
						<aside className={styles.menu}>
							<ul className={styles.list}>
								<ListItem property={termsContent} kebabify={kebabify} setTermsMarginTop={setTermsMarginTop} />
								<ListItem property={privacyContent} kebabify={kebabify} setTermsMarginTop={setTermsMarginTop} />
								<ListItem property={rulesContent} kebabify={kebabify} setTermsMarginTop={setTermsMarginTop} />
							</ul>
						</aside>

						<Scrollbars
							style={{ height: 1000 }}
							renderTrackHorizontal={() => <div style={{backgroundColor: "#fff"}} className="tracking"/>}
						>
							<div className={styles.content}>
								<ContentItem property={termsContent} kebabify={kebabify} />
								<ContentItem property={privacyContent} kebabify={kebabify} />
								<ContentItem property={rulesContent} kebabify={kebabify} />
							</div>
						</Scrollbars>
					</div>
				</Container>
			</section>
    </Layout>
  )
}

const ListItem = ({property, kebabify, setTermsMarginTop}) => {
	return (
		<li>
			<a
				className={styles.item}
				href={`#${kebabify(property.title)}`}
				onClick={() => setTermsMarginTop(onClickedWindowSize)}
			>{property.title}</a>

			{
				property.sections &&
				<ul className={styles.dropdown}>
					{
						property.sections.map((section, index) =>
							<li key={`${section.heading}_${index}`}>
								<a
									className={styles.dropdown__item}
									href={`#${kebabify(section.heading)}`}
									onClick={() => setTermsMarginTop(onClickedWindowSize)}
								><TriangleRight /> {section.heading}</a>
							</li>
						)
					}
				</ul>
			}
		</li>
	)
}

const ContentItem = ({property, kebabify}) => {
	const effective = property?.effective.split(':');
	const modified = property?.modified.split(':');

	return (
		<article className={styles.content__group}>
			<div className={styles.content__head}>
				<h1 id={kebabify(property.title)}>{property.title}</h1>
				<p>
					{effective[0]}: <strong>{effective[1]}</strong> <br />
					{modified[0]}: <strong>{modified[1]}</strong>
				</p>
			</div>
			<div className={styles.content__body}>
				{
					property.sections.map((section, index) =>
						<div
							className={styles.term__content}
							key={`${section.heading}_${index}`}
							id={kebabify(section.heading)}
						>
							<h2>{section.heading}</h2>
							<div dangerouslySetInnerHTML={{__html: section.content}}></div>
						</div>
					)
				}
			</div>
		</article>
	)
}

export const Query = graphql`
  query TermsTemplate {
    terms: wpPage(slug: { eq: "terms-conditions" }) {
      id
      seoMetaFields {
        wpFields {
          languageValue {
            slug
            name
          }
          seoTitle
          seoDescription
        }
      }
      termsFields {
        sectionTerms {
          effective
          languageValue {
            name
            slug
          }
          modified
          title
          sections {
            content
            heading
          }
        }
      }
    }
    privacy: wpPage(slug: { eq: "privacy-policy" }) {
      id
      termsFields {
        sectionTerms {
          effective
          languageValue {
            name
            slug
          }
          modified
          title
          sections {
            content
            heading
          }
        }
      }
    }
    rules: wpPage(slug: { eq: "rules" }) {
      id
      termsFields {
        sectionTerms {
          effective
          languageValue {
            name
            slug
          }
          modified
          title
          sections {
            content
            heading
          }
        }
      }
    }
  }
`

export default TermsTemplate
